export const numberWithCommas = (x: string | number, p?: null | number) => {
  if (x == "-") return x;
  if (p || p === 0) x = Number(x).toFixed(p);
  if (isNaN(Number(x))) return "0.00";
  if (!x) return "0.00";
  let parts = x?.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const toNumber = (x: string | number) => {
  if (typeof x !== "number" && typeof x !== "string") return 0;
  let value = x.toString();

  value = value
    .replaceAll(",", "")
    .replaceAll(" ", "")
    .replace(/[^0-9,.-]+/g, "");

  value = isNaN(Number(value)) ? "0" : value;

  return value;
};

export const filterNumber = (x: any) => {
  if (typeof x !== "number" && typeof x !== "string") return x;

  let value = x.toString();

  if (!/^[0-9\-\.\,]+$/gi.test(value)) {
    return x;
  }

  return Number(toNumber(value));
};

export const padd2 = (n: string | Number) => {
  return Number(n) > 9 ? "" + n : "0" + n;
};
