/* eslint-disable no-unused-vars */
import Environment from "../../utils/environment";
import axios from "..";
import {
  ytdSpends,
  ytdAuthorisations,
  auhorisationsByPolicy,
  spendsByPolicy,
  exceptionsByPolicy,
  upcomingVisaExp,
  mapsData,
  getDivisions,
  GET_SERVICE_BY_POLICY,
  CHECK_FAVOURITE_NAME,
  CREATE_FAVOURITE,
  ExportData,
  ytdActiveFiles,
  assignmentExpCountURL,
  assignmentExpirationURL,
} from "../../resources/constants";

export interface ILandingInfo {
  id: number;
  title: string;
  description: string;
  background?: string;
  error: any;
  data: [];
}

enum Landing {
  LANDING_PAGE = "landing",
}

export default class dashboardService {
  public static async getYtdSpends(filters: any) {
    try {
      const { data }: { data: any } = await axios.post(ytdSpends, filters);
      return data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  public static async getYtdAuthorisations(filters: any) {
    try {
      const { data }: { data: any } = await axios.post(
        ytdAuthorisations,
        filters,
      );
      return data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  public static async getYtdActiveFiles(filters: any) {
    try {
      const { data }: { data: any } = await axios.post(ytdActiveFiles, filters);
      return data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  public static async getAuthsByPolicy(filters: any) {
    try {
      const { data }: { data: any } = await axios.post(
        auhorisationsByPolicy,
        filters,
      );
      return data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  public static async getSpendsByPolicy(filters: any) {
    try {
      const { data }: { data: any } = await axios.post(spendsByPolicy, filters);
      return data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }
  public static async getExportsData(filters: any) {
    try {
      const { data }: { data: any } = await axios.post(ExportData, filters);
      return data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  public static async getExceptionsByPolicy(filters: any) {
    try {
      const { data }: { data: any } = await axios.post(
        exceptionsByPolicy,
        filters,
      );
      return data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  public static async getVisaExpiryPolicy(filters: any) {
    try {
      const { data }: { data: any } = await axios.post(
        upcomingVisaExp,
        filters,
      );
      return data;
    } catch (error: any) {
      throw new Error(error.error.message);
    }
  }

  public static async getMapsData(filters: any) {
    const { data }: { data: any } = await axios.post(mapsData, filters);

    //     if(filters.filters.domestic) {
    //       return JSON.parse(`{
    //   "mapsData": {
    //     "originStates": [
    //       {
    //         "count": 22,
    //         "originState": "KY"
    //       },
    //       {
    //         "count": 46,
    //         "originState": "LA"
    //       },
    //       {
    //         "count": 33,
    //         "originState": "MA"
    //       },
    //       {
    //         "count": 35,
    //         "originState": "MD"
    //       },
    //       {
    //         "count": 3,
    //         "originState": "ME"
    //       },
    //       {
    //         "count": 43,
    //         "originState": "MI"
    //       },
    //       {
    //         "count": 27,
    //         "originState": "MN"
    //       },
    //       {
    //         "count": 26,
    //         "originState": "MO"
    //       },
    //       {
    //         "count": 21,
    //         "originState": "MS"
    //       }
    //     ],
    //     "destinationStates": [
    //       {
    //         "count": 22,
    //         "destinationState": "KY"
    //       },
    //       {
    //         "count": 46,
    //         "destinationState": "LA"
    //       },
    //       {
    //         "count": 33,
    //         "destinationState": "MA"
    //       },
    //       {
    //         "count": 35,
    //         "destinationState": "MD"
    //       },
    //       {
    //         "count": 3,
    //         "destinationState": "ME"
    //       },
    //       {
    //         "count": 43,
    //         "destinationState": "MI"
    //       },
    //       {
    //         "count": 27,
    //         "destinationState": "MN"
    //       },
    //       {
    //         "count": 26,
    //         "destinationState": "MO"
    //       },
    //       {
    //         "count": 21,
    //         "destinationState": "MS"
    //       }
    //     ]
    //   }
    // }`);
    //     } else {
    //     return JSON.parse(`{
    //       "mapsData": {
    //         "destinationCountries": [
    //           {
    //             "count": 1,
    //             "destinationCountry": "AFG"
    //           },
    //           {
    //             "count": 4,
    //             "destinationCountry": "ARE"
    //           },
    //           {
    //             "count": 8,
    //             "destinationCountry": "AUS"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "BEL"
    //           },
    //           {
    //             "count": 2,
    //             "destinationCountry": "BHS"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "BRA"
    //           },
    //           {
    //             "count": 86,
    //             "destinationCountry": "CAN"
    //           },
    //           {
    //             "count": 30,
    //             "destinationCountry": "CHE"
    //           },
    //           {
    //             "count": 4,
    //             "destinationCountry": "CHL"
    //           },
    //           {
    //             "count": 23,
    //             "destinationCountry": "CHN"
    //           },
    //           {
    //             "count": 10,
    //             "destinationCountry": "CZE"
    //           },
    //           {
    //             "count": 8,
    //             "destinationCountry": "DEU"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "DNK"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "DZA"
    //           },
    //           {
    //             "count": 3,
    //             "destinationCountry": "ESP"
    //           },
    //           {
    //             "count": 53,
    //             "destinationCountry": "FRA"
    //           },
    //           {
    //             "count": 52,
    //             "destinationCountry": "GBR"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "GUY"
    //           },
    //           {
    //             "count": 8,
    //             "destinationCountry": "HKG"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "HUN"
    //           },
    //           {
    //             "count": 6,
    //             "destinationCountry": "IND"
    //           },
    //           {
    //             "count": 7,
    //             "destinationCountry": "IRL"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "ISR"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "ITA"
    //           },
    //           {
    //             "count": 7,
    //             "destinationCountry": "JPN"
    //           },
    //           {
    //             "count": 7,
    //             "destinationCountry": "KOR"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "LBN"
    //           },
    //           {
    //             "count": 2,
    //             "destinationCountry": "MEX"
    //           },
    //           {
    //             "count": 2,
    //             "destinationCountry": "MYS"
    //           },
    //           {
    //             "count": 15,
    //             "destinationCountry": "NLD"
    //           },
    //           {
    //             "count": 2,
    //             "destinationCountry": "NOR"
    //           },
    //           {
    //             "count": 2,
    //             "destinationCountry": "PHL"
    //           },
    //           {
    //             "count": 4,
    //             "destinationCountry": "POL"
    //           },
    //           {
    //             "count": 3,
    //             "destinationCountry": "PRT"
    //           },
    //           {
    //             "count": 3,
    //             "destinationCountry": "QAT"
    //           },
    //           {
    //             "count": 2,
    //             "destinationCountry": "SAU"
    //           },
    //           {
    //             "count": 25,
    //             "destinationCountry": "SGP"
    //           },
    //           {
    //             "count": 2,
    //             "destinationCountry": "SWE"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "SWZ"
    //           },
    //           {
    //             "count": 1,
    //             "destinationCountry": "TTO"
    //           },
    //           {
    //             "count": 3,
    //             "destinationCountry": "TUR"
    //           },
    //           {
    //             "count": 3,
    //             "destinationCountry": "TWN"
    //           },
    //           {
    //             "count": 2686,
    //             "destinationCountry": "USA"
    //           }
    //         ],
    //         "originCountries": [
    //           {
    //             "count": 1,
    //             "originCountry": "ALB"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "ANT"
    //           },
    //           {
    //             "count": 5,
    //             "originCountry": "ARE"
    //           },
    //           {
    //             "count": 4,
    //             "originCountry": "AUS"
    //           },
    //           {
    //             "count": 2,
    //             "originCountry": "AUT"
    //           },
    //           {
    //             "count": 6,
    //             "originCountry": "BEL"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "BGD"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "BGR"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "BHS"
    //           },
    //           {
    //             "count": 28,
    //             "originCountry": "BRA"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "BRB"
    //           },
    //           {
    //             "count": 73,
    //             "originCountry": "CAN"
    //           },
    //           {
    //             "count": 9,
    //             "originCountry": "CHE"
    //           },
    //           {
    //             "count": 4,
    //             "originCountry": "CHL"
    //           },
    //           {
    //             "count": 15,
    //             "originCountry": "CHN"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "COL"
    //           },
    //           {
    //             "count": 4,
    //             "originCountry": "CZE"
    //           },
    //           {
    //             "count": 14,
    //             "originCountry": "DEU"
    //           },
    //           {
    //             "count": 2,
    //             "originCountry": "DNK"
    //           },
    //           {
    //             "count": 2,
    //             "originCountry": "EGY"
    //           },
    //           {
    //             "count": 10,
    //             "originCountry": "ESP"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "FIN"
    //           },
    //           {
    //             "count": 8,
    //             "originCountry": "FRA"
    //           },
    //           {
    //             "count": 41,
    //             "originCountry": "GBR"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "GRC"
    //           },
    //           {
    //             "count": 13,
    //             "originCountry": "HKG"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "HRV"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "HUN"
    //           },
    //           {
    //             "count": 48,
    //             "originCountry": "IND"
    //           },
    //           {
    //             "count": 10,
    //             "originCountry": "IRL"
    //           },
    //           {
    //             "count": 7,
    //             "originCountry": "ITA"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "JOR"
    //           },
    //           {
    //             "count": 6,
    //             "originCountry": "JPN"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "KEN"
    //           },
    //           {
    //             "count": 11,
    //             "originCountry": "KOR"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "LKA"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "MCO"
    //           },
    //           {
    //             "count": 21,
    //             "originCountry": "MEX"
    //           },
    //           {
    //             "count": 2,
    //             "originCountry": "MLT"
    //           },
    //           {
    //             "count": 2,
    //             "originCountry": "MYS"
    //           },
    //           {
    //             "count": 10,
    //             "originCountry": "NLD"
    //           },
    //           {
    //             "count": 5,
    //             "originCountry": "NOR"
    //           },
    //           {
    //             "count": 1,
    //             "originCountry": "NZL"
    //           },
    //           {
    //             "count": 3,
    //             "originCountry": "OMN"
    //           },
    //           {
    //             "count": 5,
    //             "originCountry": "PHL"
    //           }
    //         ]
    //       }
    //     }`)}

    return data;
  }

  public static async servicesByPolicy(filters: any) {
    return await axios.post(GET_SERVICE_BY_POLICY, filters);
  }

  private static async getAuthDivisions() {
    const { data }: { data: any } = await axios.get(getDivisions);
    return data;
  }

  public static async getYtdSpendsApi(filters: any) {
    return await dashboardService.getYtdSpends(filters);
  }

  public static async getAssignmentExpCountApi(filters: any) {
    return axios.post(assignmentExpCountURL, filters);
  }

  public static async getYtdAuthorisationsApi(filters: any) {
    return await dashboardService.getYtdAuthorisations(filters);
  }

  public static async getYtdActiveFilesApi(filters: any) {
    return await dashboardService.getYtdActiveFiles(filters);
  }

  public static async getAuthsByPolicyApi(filters: any) {
    return await dashboardService.getAuthsByPolicy(filters);
  }

  public static async getSpendsByPolicyApi(filters: any) {
    return await dashboardService.getSpendsByPolicy(filters);
  }

  public static async getExceptionsByPolicyApi(filters: any) {
    return await dashboardService.getExceptionsByPolicy(filters);
  }

  public static async getAssignmentExpirationApi(filters: any) {
    const types =
      filters.filters?.assignmentType
        ?.filter((item) => item.checked)
        .map((item) => item.id) || [];
    return await axios.post(assignmentExpirationURL, {
      filters: {
        ...filters.filters,
        assignmentType: types.length < 1 ? null : types,
      },
    });
  }

  public static async getVisaExpiryPolicyApi(filters: any) {
    return await dashboardService.getVisaExpiryPolicy(filters);
  }

  public static async getMapsDataApi(filters: any) {
    return await dashboardService.getMapsData(filters);
  }

  public static async getAuthDivisionsApi() {
    return await dashboardService.getAuthDivisions();
  }

  public static async checkFavorite(name: string) {
    return await axios.get(CHECK_FAVOURITE_NAME + "/?name=" + name);
  }

  public static async saveFavorite(data: any) {
    return await axios.put(CREATE_FAVOURITE, data);
  }

  public static async exportData(data: any) {
    return await axios.post(ExportData, data);
  }
}
